/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Receipt from './scenes/Receipt';

function App() {
  return (
    <Switch>
      <Route
        path="/orange-data/:id?"
        render={(props) => <Receipt isOrangeDataReceipt {...props} />}
      />
      <Route path="/:id?" component={Receipt} />
    </Switch>
  );
}

export default App;
