
const loadReceipt = async (id) => {
  const request = new Request(`/api/v2/receipts/public/${id}`, { method: 'get' });
  const response = await fetch(request);
  const body = await response.json();
  if (response.status >= 200 && response.status < 300) return body;
  const error = typeof body === 'object' ? JSON.stringify(body) : body;
  throw new Error(`Ошибка при выполнении запроса: ${error}`);
};


export default loadReceipt;
