import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Grid } from '@material-ui/core';

import logo from '../../../assets/images/logo-dark.png';

const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'block',
    width: theme.spacing(9),
  },
}));


export default function TopBar() {
  const classes = useStyles();
  return (
    <AppBar position="static" color="default">
      <Toolbar className={classes.root}>
        <Grid container justify="center">
          <Grid item><img src={logo} alt="" className={classes.logo} /></Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
