import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as Error } from '../../../../assets/images/cheque-error-ic.svg';

const useStyles = makeStyles({
  fullHeight: { height: '100%' },
});

export default function EmptyState({ isOrangeDataReceipt, text }) {
  const classes = useStyles();
  return (
    <Grid container direction="column" alignItems="center" justify="center" className={classes.fullHeight}>
      <Grid item>
        <Error width={149} />
      </Grid>
      <Grid item xs={3}>
        {isOrangeDataReceipt ? (
          <Typography>Ваш чек скоро появится здесь</Typography>
        ) : (
          <Typography align="center">{text}</Typography>
        )}
      </Grid>
    </Grid>
  );
}

EmptyState.propTypes = { isOrangeDataReceipt: PropTypes.bool, text: PropTypes.string };
EmptyState.defaultProps = {
  isOrangeDataReceipt: false,
  text: 'Такого чека не существует или к нему закрыт доступ. Обратитесь к продавцу, чтобы посмотреть чек',
};
